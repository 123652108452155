<template>
  <vs-popup ref="popupRef" title="Confirm Delete" :active.sync="isActive">
    <div class="my-2">Are you sure you want to delete this coursefeeds?</div>
    <vs-button @click="deleteCoursefeed">Delete</vs-button>
  </vs-popup>
</template>

<script>
import { API, graphqlOperation } from '@aws-amplify/api'
import { deleteCourseFeed } from '@/graphql/mutations'
import { createErrorObject, createSuccessObject } from '@/utils'

export default {
  name: 'CourseFeedDeletePopup',
  data () {
    return {
      isActive: false,
      id: '',
      index: '',
    }
  },
  methods: {
    async deleteCoursefeed () {
      try {
        this.$vs.loading()
        const deleteRes = await API.graphql(graphqlOperation(deleteCourseFeed, {
          input: {
            id: this.id
          }
        }))

        this.$vs.notify(createSuccessObject('Success', 'Coursefeed deleted'))
        this.$emit('deleteFinished', this.index)
        this.isActive = false
         this.$vs.loading.close()
      } catch (err) {
        this.$vs.notify(createErrorObject('Error', 'Cannot delete educator\s coursefeed'))
        console.log(err)
      }
    }
  }
}
</script>