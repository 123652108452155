<template>
  <vs-popup ref="popupRef" title="Update session details" :active.sync="active">
    <div class="px-3">
      <div class="">
      <label class="ml-1">Session Title:</label>
      <vs-input class="w-full" v-model="title"/>
      </div>

      <label class="ml-1 mt-4">Service Offered:</label>
      <v-select 
        class="w-full" 
        v-model="servicesOffered"
        :options="serviceList"
        >
      </v-select>
      
      <label class="ml-1 mt-4">Study Area/Category:</label>
      <v-select
          v-model="category"
          label="text"
          :options="categories"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          @input="fetchTopic()"
        >
      </v-select>

      <label class="ml-1 mt-4">Topics:</label>      
      <v-select 
        :disabled="category === ''"
        class="w-full" 
        v-model="subjects" 
        taggable
        multiple
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        :options="subjectList"
        :closeOnSelect="false"
        @option:created="handleSubjectOptionCreate"
        ></v-select>

      <day-checkbox ref="dayCheckboxRef" v-for="(day, index) in weekDays" :day="day" :key="index" :value="index"/>
      
      <vs-select class="w-full mt-4" v-model="session" label="Sessions">
        <vs-select-item v-for="(item, index) in sessions" :key="index" :text="item.text" :value="item.value"/>
      </vs-select>

      <label class="ml-1 mt-4">Availability:</label>      
      <vs-textarea rows="4" class="w-full" v-model="availability"/>
      
      <!-- <label class="ml-1 mt-4">Description:</label>      
      <vs-textarea rows="6" v-model="description"></vs-textarea> -->

      <div class="mt-4">
     <label class="">Description</label>
     <quill-editor v-model="description"></quill-editor>
      </div>

      <div class="my-4">
        <vs-input label="Pricing Name" class="w-full" v-model="servicePackage.name" />
        <vs-input label="Price " class="w-full mt-2" v-model="servicePackage.price" />
        <vs-input label="Discount Price" class="w-full mt-2" v-model="servicePackage.discountPrice" />
      </div>

    <div class="mt-4">
      <label class="ml-1 block">Session Image:</label>
      <div class="md:flex items-center">
        <div class="">
        <img class="block rounded-lg" :src="imageURL" width="200px" />
        <input type="file" ref="imageInputRef" @change="handleImageChange"  />
        </div>
         <div class="ml-3">
        <vs-button class="mt-4" size="small" @click="toggleImageUpload">Update Image</vs-button>
         </div>
      </div>
    </div>

      <div class="mt-5 text-right">
      <vs-button @click="updateService" class="">Update Session</vs-button>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
import { API, graphqlOperation } from '@aws-amplify/api' 
import { createErrorObject, createSuccessObject } from '@/utils'
import { Storage } from '@aws-amplify/storage'
import { updateEducatorService, updateEducatorServicePackage } from '@/graphql/mutations'
import DayCheckbox from '@/views/pages/List-my-services/DayCheckbox'
import { listCategorys, topicsByCategory } from '@/graphql/queries';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'EducatorServiceEditPopup',
  components: {
    vSelect,
    DayCheckbox,
    quillEditor
  },
  data () {
    return {
      id: '',
      index: '',
      title: '',
      image: '',
      imageFile: '',
      imageURL: '',
      servicesOffered: '',
      serviceList: [
          'Higher Education',
        'Online Education',
        'Student Recruitment',
        "Career Guidance",
        "Employment Services"
      ],
      category: '',
      categories: [],
      availability: '',
      subjects: [],
      subjectList: [],
      description: '',
      active: false,
      weekDays: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ],
      session: '',
      sessions: [
        { text: '30 minutes', value: 30 },
        { text: '60 minutes', value: 60 },
        { text: '90 minutes', value: 90 }
      ],
      schedule: '',
      servicePackage: ''
    }
  },
  created () {
    this.fetchCategory()
  },
  methods: {
    async fetchCategory () {      
      try {        
        const res = await API.graphql(graphqlOperation(listCategorys))
        this.categories = res.data.listCategorys.items.map((item) => {
          return { text: item.name, val: item.id }          
        })         
      } catch (err) {
        console.log('fetchCategory', err)
      }
    },
    async fetchTopic () {
      this.subjects = []
      try {
        let category = ''
        this.subjectList = []
        category = this.category.val
        const res = await API.graphql(graphqlOperation(topicsByCategory, {
          category
        }))
        //  this.subjectList = res.data.topicsByCategory.items.map(item => {
        //  return { text: item.name, val: item.id }
        // })
        for (const topic of res.data.topicsByCategory.items) {
          this.subjectList.push(topic.name)
        }
        console.log(this.subjects);
      } catch (err) {
        console.log('fetchTopic', err)
      }
    },
    toggleImageUpload () {
      this.$refs.imageInputRef.click()
    },
    handleImageChange (e) {
      this.imageFile = e.target.files[0]
      const url = URL.createObjectURL(e.target.files[0])
      this.imageURL = url
    },
    onSelectClick () {
      this.$refs.popupRef.classList.toggle('no-scroll')
    },
    async handleSubjectOptionCreate (newOption) {
      const name = newOption.text ? newOption.text : newOption
      try {
        const res = await API.graphql(
          graphqlOperation(createTopic, {
            input: {
              category: this.category.val,
              name
            }
          })
        )
        
        this.subjectList.push({
          text: name,
          val: res.data.createTopic.id
        })
      } catch (err) {
        console.log('handleSubjectOptionCreate', err)
      }
    },
    async updateService () {
      try {
        this.$vs.loading()
        if (!this.validateInput()) return

        let uploadImageRes = ''
        if (this.imageFile) {
          uploadImageRes = await Storage.put(this.imageFile.name, this.imageFile)
        }

        const scheduleDays = this.$refs.dayCheckboxRef.filter(item => item.isSelected).map(item => {
          return {
            day: item.value,
            from: item.from,
            to: item.to
          }
        })

        await API.graphql(graphqlOperation(updateEducatorServicePackage, {
          input: {
            id: this.servicePackage.id,
            name: this.servicePackage.name,
            price: this.servicePackage.price,
            discountPrice: this.servicePackage.discountPrice
          }
        }))

        const updateRes = await API.graphql(graphqlOperation(updateEducatorService, {
          input: {
            id: this.id,
            title: this.title,
            servicesOffered: this.servicesOffered,
            category: this.category.text,
            subjects: this.subjects,
            description: this.description,
            session: this.session,
            schedule: scheduleDays,
            availability: this.availability,
            images: uploadImageRes !== '' ? [uploadImageRes.key] : [this.image]
          }
        }))

        this.$vs.notify(createSuccessObject('Success', 'Service update succeeded'))
        updateRes.data.updateEducatorService.imageURL = []
        for (const img of updateRes.data.updateEducatorService.images) {
          const url = await Storage.get(img)
          updateRes.data.updateEducatorService.imageURL.push(url)
        }
        
        this.$emit('updateFinished', { service:  updateRes.data.updateEducatorService, index: this.index })
        this.active = false
        this.$vs.loading.close()
      } catch (err) {
        console.log(err)
        this.$vs.notify(createErrorObject('Error', 'Cannot update educator\'s service'))
      }
    },
    setSchedule () {
      for (const schedule of this.schedule) {
        this.$refs.dayCheckboxRef[schedule.day].isSelected = true
        this.$refs.dayCheckboxRef[schedule.day].from = schedule.from
        this.$refs.dayCheckboxRef[schedule.day].to = schedule.to
      } 
    },
    validateInput () {
      if (this.title === '') {
        this.$vs.notify(createErrorObject('Error', 'Title field cannot be empty'))
        return false
      }

      if (this.category === '') {
        this.$vs.notify(createErrorObject('Error', 'Category field cannot be empty'))
        return false
      }

      const schedule = this.$refs.dayCheckboxRef.filter(item => item.isSelected)
      for (const sc of schedule) {
        const isScheduleValid = sc.validateInput()
        if (!isScheduleValid) return false
      }

      if (this.availability === '') {
        this.$vs.notify(createErrorObject('Error', 'Availability field cannot be empty'))
        return false
      }

      if (this.subjects.length === 0) {
        this.$vs.notify(createErrorObject('Error', 'Subjects field cannot be empty'))
        return false
      }

      if (this.description === '') {
        this.$vs.notify(createErrorObject('Error', 'Description field cannot be empty'))
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>
.no-scroll {
  overflow-y: hidden !important;
}
</style>