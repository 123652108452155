<template>
  <div>
    <p class="text-center mt-2" v-if="this.courseFeeds.length === 0">No groups listed yet, start creating new groups now!</p>
    <div>      
      <div
        class="vx-card mt-base"
         v-for="(cf, index) in courseFeeds"
        :key="index"
      >
        <div class="vx-row item-details no-gutter"> 
          <div
            class="
              vx-col
              sm:w-1/4
              w-full
              item-img-container
              bg-white
              flex
              items-center
              justify-center
              product-image
              cursor-pointer
            "
          >
            <img class="p-4" :src="cf.imageURL ? cf.imageURL : ''" />
          </div>
          <div class="vx-col sm:w-1/2 flex items-center">
            <div class="p-5 pt-6 w-full">
              <div class="flex items-center justify-between">
                <h5 class="font-semibold mb-1 text-primary">
                  {{ cf.title }}
                </h5>
                <div>
                  <template >
                    <feather-icon
                      class="cursor-pointer hover:bg-grey p-2 rounded"
                      @click="editCourseFeed(cf, index)"
                      icon="EditIcon"
                    />
                    <feather-icon
                      class="cursor-pointer hover:bg-grey p-2 rounded"
                      @click="toggleDeleteCourseFeed(cf, index)"
                      icon="Trash2Icon"
                    />
                  </template>
                </div>
              </div>

              <div class="mt-1">
                <p>
                  <span class="font-semibold">Headline:</span
                  ><span class="text-muted ml-2">{{ cf.headline }}</span>
                </p>
              </div>
              <div class="mt-1">
                <p>
                  <span class="font-semibold">Category:</span>
                  <span class="text-muted ml-2">{{ cf.category }}</span>
                </p>
              </div>

              <div class="mt-1">
                <span class="font-semibold">Topics:</span>
                <div class="flex flex-wrap mt-1">
                  <div
                    class="rounded-full bg-custom-light px-3 py-2 mr-3"
                     v-for="(item, index) in cf.topics"
                    :key="index"
                  >
                    <span class="">{{ item }}</span>
                  </div>
                </div>
              </div>

              <div class="mt-1 about-coursefeeds">
                <span class="font-semibold">Description:</span>
                <div v-html="cf.description" class="text-muted text-truncate">
                  {{ cf.description }}
                  </div>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/4 w-full flex border-left p-4">
            <div class="flex flex-col w-full">
              <!-- <div class="text-warning flex self-end border border-solid border-warning py-1 px-2 rounded">
                   <span class="text-sm mr-1">4.5</span>
                  <feather-icon icon="StarIcon" svgClasses="h-4 w-4" />
                </div>
                 <div class="self-end">
                   <span class="text-sm"><span class="font-semibold">60</span> reviews</span>
                 </div> -->
              <div class="my-6">
                <h5 class="">
                   <span
                    v-if="cf.price"
                    class="font-bold text-success"
                    >{{ cf.price === 0 ? 'Free' : cf.discountPrice}}</span
                  >
                  <span
                    class="text-primary ml-2"
                    :class="
                      cf.discountPrice
                        ? 'line-through'
                        : ''
                    "
                    >{{ cf.price === 0 ? 'Free' : cf.price }}</span
                  >
                 
                </h5>
                <vs-button
                  @click="courseDetails(cf)"
                  class="mt-4 cursor-pointer flex items-center justify-center"
                  >More details</vs-button
                >
                <!-- View Service popup -->
                <coursefeed-list-delete-popup ref="deletePopup" @deleteFinished="handleDeleteFinished" />
                <coursefeed-list-edit-popup ref="editPopup" @updateFinished="handleUpdateFinished" @onUpdated="handleUpdated"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from '@aws-amplify/api'
import { Storage } from '@aws-amplify/storage'
import { courseFeedByUser } from '@/graphql/queries'

import CoursefeedListEditPopup from './CoursefeedListEditPopup'
import CoursefeedListDeletePopup from './CoursefeedListDeletePopup'

export default {
  name: 'PartnerCoursFeeds',
  data () {
    return {
      courseFeeds: []
    }
  },
  computed : {
    isEdit () {
      return this.$store.state.auth.userData.name === this.$route.params.userName
    }
  },
  
  components: {
    CoursefeedListEditPopup,
    CoursefeedListDeletePopup
  },
  methods: {
    getHtmlValue(val) {
      var div = document.createElement("div");
      div.innerHTML = val;
      // console.log(div.innerHTML);
      return div.innerHTML.replace(/<\/?[^>]+(>|$)/g, "");
    },
    courseDetails(cf) {
      this.$router.push(`/course-details/${cf.id}`);
    },
    async fetchCourseFeeds () {
          console.log(this.$store.state.auth.userData.name)
      try {
        const res = await API.graphql(graphqlOperation(courseFeedByUser, {
          user: this.$store.state.auth.userData.name,
          filter: {status: {eq: "Active"}}
        }))
        for (const cf of res.data.courseFeedByUser.items) {
          if (cf.image) {
            const url = await Storage.get(cf.image)
            cf.imageURL = url
          }
        }

        this.courseFeeds = res.data.courseFeedByUser.items
      } catch (err) {
        console.log(err)
      }
    },
    handleDeleteFinished (index) {
      this.courseFeeds.splice(index, 1)
    },
    handleUpdateFinished ({ courseFeed, index }) {
      this.courseFeeds.splice(index, 1, courseFeed)
    },
    viewCourseFeed () {

    },
    handleUpdated ({ courseFeed, index }) {
      this.courseFeeds.splice(index, 1, courseFeed)
    },
    async editCourseFeed (cf, index) {
      const editPopup = this.$refs.editPopup[index]
      editPopup.id = cf.id
      editPopup.index = index
      editPopup.title = cf.title
      editPopup.category = cf.category
      // match category
      for (const cat of editPopup.categories) {
        if (cat.text === cf.category) {
          editPopup.category = cat
          await editPopup.fetchTopic(cat)
          editPopup.topics = []
          for (const top of editPopup.topicsList) {
            if (cf.topics.includes(top.text)) {
              editPopup.topics.push(top)
            }
          }
          break
        }
      }

      
      editPopup.headline = cf.headline
      editPopup.description = cf.description
      editPopup.type = cf.type
      editPopup.price = cf.price
      editPopup.discountPrice = cf.discountPrice
      if (cf.image) {
        const url = await Storage.get(cf.image)
        editPopup.previewImage = url
      }
      editPopup.image = cf.image
      editPopup.video.name = cf.video
      editPopup.isActive = true
    },
    toggleDeleteCourseFeed (cf, index) {
      const deletePopup = this.$refs.deletePopup[index]
      deletePopup.id = cf.id
      deletePopup.index = index
      deletePopup.isActive = true
    }
  },
  created () {
    this.fetchCourseFeeds()
  }
}
</script>
