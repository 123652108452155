<template>
  <div>
    <p class="text-center mt-2" v-if="this.services.length === 0">No sesssions listed yet!</p>
    <div >      
      <div
        class="vx-card mt-base"
        v-for="(service, index) in services"
        :key="index"
      >
        <div class="vx-row item-details no-gutter">
          <div
            class="
              vx-col
              sm:w-1/4
              w-full
              item-img-container
              bg-white
              flex
              items-center
              justify-center
              product-image
              cursor-pointer
            "
          >
            <img class="p-4" :src="service.imageURL[0]" />
          </div>
          <div class="vx-col sm:w-1/2 flex items-center">
            <div class="p-5 pt-6 w-full">
              <div class="flex items-center justify-between">
                <h5 class="font-semibold mb-1 text-primary">
                    {{ service.title }}
                </h5>
                <div>
                  <template>
                    <feather-icon
                      class="cursor-pointer hover:bg-grey p-2 rounded"
                      @click="editService(service, index)"
                      icon="EditIcon"
                    />
                    <feather-icon
                      class="cursor-pointer hover:bg-grey p-2 rounded"
                      @click="toggleDeleteService(service, index)"
                      icon="Trash2Icon"
                    />
                  </template>
                </div>
              </div>

              <div class="mt-1">
                <p>
                  <span class="font-semibold">Service Offered:</span
                  ><span class="text-muted ml-2">{{ service.servicesOffered[0] }} </span>
                </p>
              </div>
              <div class="mt-1">
                <p>
                  <span class="font-semibold">Category:</span>
                  <span class="text-muted ml-2">{{ service.category }}</span>
                </p>
              </div>

              <div class="mt-1">
                <span class="font-semibold">Topics:</span>
                <div class="flex flex-wrap mt-1">
                  <div
                    class="rounded-full bg-custom-light px-3 py-2 mr-3"
                     v-for="(item, index) in service.subjects"
                    :key="index"
                  >
                    <span class="">{{ item }} </span>
                  </div>
                </div>
              </div>

              <div class="mt-1 about-coursefeeds">
                <span class="font-semibold">Description:</span>
                <div v-html="service.description" class="text-muted text-truncate">
                {{ service.description }}
                </div>
              </div>
              <!-- <div class="mt-1">
                <p>
                  <span class="font-semibold">Availability:</span
                  ><span class="text-muted ml-2">{{
                    service.availability
                  }}</span>
                </p>
              </div> -->

            </div>
          </div>
          <div class="vx-col sm:w-1/4 w-full flex border-left p-4">
            <div class="flex flex-col w-full">
              <!-- <div class="text-warning flex self-end border border-solid border-warning py-1 px-2 rounded">
                   <span class="text-sm mr-1">4.5</span>
                  <feather-icon icon="StarIcon" svgClasses="h-4 w-4" />
                </div>
                 <div class="self-end">
                   <span class="text-sm"><span class="font-semibold">60</span> reviews</span>
                 </div> -->
              <div class="my-6">
                <h5 class=""> 
                  <span
                    v-if="service.packages.items[0].discountPrice"
                    class="font-bold text-success"
                    >${{ service.packages.items[0].discountPrice }}</span
                  >
                  <span
                    class="text-primary ml-2"
                    :class="
                      service.packages.items[0].discountPrice
                        ? 'line-through'
                        : ''
                    "
                    >${{ service.packages.items[0].price }}</span
                  >
                </h5>
                <!-- <vs-button
                  @click="serviceDetails(service)"
                  class="mt-4 cursor-pointer flex items-center justify-center"
                  >Book Now</vs-button
                > -->
                <!-- View Service popup -->
                <session-listing-delete-popup ref="deletePopup" @deleteFinished="handleDeleteFinished" />
                <session-listing-edit-popup ref="editPopup" @updateFinished="handleUpdateFinished"/>

                <vs-button
                  @click="serviceDetails(service)"
                  class="mt-4 cursor-pointer flex items-center justify-center"
                  type="border"
                  >More details</vs-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
                <view-service-popup ref="viewPopup"  />

     <a @click="openAddSession()" v-if="isPartner" href="#"  class="coursefeeds-add-btn">
      <i class="fa fa-plus"></i>
    </a>
    <div class="">
      <vs-popup title="List My Services" :active.sync="listServices">
        <div class="p-4">
          <list-my-services-tab @formSubmitted="listServices = false" />
        </div>
      </vs-popup>
    </div>

  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import { API, graphqlOperation } from '@aws-amplify/api'
import {
  educatorServicesByEducatorId,
  educatorByUser,
  sessionBookingByUser,
  sessionBookingByServiceId,
  getEducatorService,
  getUser
} from '@/graphql/queries'
import { Storage } from '@aws-amplify/storage'
import { createErrorObject } from '@/utils'
import SessionListingEditPopup from './SessionListingEditPopup.vue'
import ViewServicePopup from './ViewServicePopup.vue'
import { listCategorys, topicsByCategory } from "@/graphql/queries";
import SessionListingDeletePopup from './SessionListingDeletePopup.vue'
import moment from 'moment'



export default {
  name: 'EducatorServices',
  data () {
    return {
      listServices: false,
      popupActive: false,
      services: [],
      categories: []
    }
  },
  computed: {
    isEdit () {
      return this.$store.state.auth.userData.name === this.$route.params.userName
    },
    isPartner () {
      return this.$store.state.auth.userData.isPartner
    },
  },
  components: {
    StarRating,
    SessionListingEditPopup,
    ViewServicePopup,
    SessionListingDeletePopup,
    moment,
  
  },
  methods: {
    async openAddSession() {
      let stripeConnectedAccountKey = this.$store.state.auth.userData.stripeConnectedAccountKey;
      if(stripeConnectedAccountKey != undefined && stripeConnectedAccountKey != null){
          API.post('cpRestAPI', '/stripeaccount/accountlinks/retrieve', {
                headers: {
                  'Content-Type': 'application/json'
                },
                body: {
                  stripeConnectedAccountKey:this.$store.state.auth.userData.stripeConnectedAccountKey
              }}).then(res => {
                if(res.success === true){  
                  let details_submitted = res.response.details_submitted;
                  if(details_submitted === true){                    
                    this.listServices = true;
                  }else{             
                    this.listServices = false;
                    this.$vs.notify(createErrorObject('Error', 'Please setup your payout account under settings page to list service'));
                  }
                }
              })
              .catch(err => {
                  console.log(err);
              })
      }else{
        this.listServices = false;
        this.$vs.notify(createErrorObject('Error', 'Please setup your payout account under settings page to list service'));
      }
    },
    getHtmlValue(val) {
      var div = document.createElement("div");
      div.innerHTML = val;
      // console.log(div.innerHTML);
      return div.innerHTML.replace(/<\/?[^>]+(>|$)/g, "");
    },
    async fetchServices () {
      try {
        if (this.$store.state.auth.userData.isPartner) {
        const getEducatorRes = await API.graphql(
          graphqlOperation(educatorByUser, {
            user: this.$store.state.auth.userData.name
          })
        )

        if (!getEducatorRes.data.educatorByUser.items[0]) {
          this.$vs.notify(
            createErrorObject('Error', 'Cannot find educator data')
          )
          return
        }

        const educator = getEducatorRes.data.educatorByUser.items[0]
        const res = await API.graphql(graphqlOperation(educatorServicesByEducatorId,{
          educatorID: educator.id,
          filter: {status: {eq: "Active"}}
        }))
        const services = res.data.educatorServicesByEducatorID.items;
        for(const s of services) {
          s.imageURL = []
          for (const img of s.images) {
            const url = await Storage.get(img);
            s.imageURL.push(url);
          }
        }  
        this.services = services  
        }else{
          const booking = await API.graphql(
            graphqlOperation(sessionBookingByUser, {
              user: this.$store.state.auth.userData.name,
            })
          );
          this.booking = booking.data.sessionBookingByUser.items;
          let services = [];
          for (const bk of booking.data.sessionBookingByUser.items) {
            const service = await API.graphql(
              graphqlOperation(getEducatorService, {
                id: bk.educatorServiceID,
              })
            );
            services.push(service.data.getEducatorService);
          }
           for(const s of services) {
              s.imageURL = []
              for (const img of s.images) {
                const url = await Storage.get(img);
                s.imageURL.push(url);
              }
            }  
            this.services = services 
        }

        // for (const service of educator.services.items) {
        //   service.imageURL = []
        //   for (const img of service.images) {
        //     const url = await Storage.get(img)
        //     service.imageURL.push(url)
        //   }
        // }
        // this.services = educator.services.items
      } catch (err) {
        console.log(err)
      }
    },
    editService (service, index) {
      const editPopup = this.$refs.editPopup[index]
      editPopup.id = service.id
      editPopup.index = index
      editPopup.title = service.title
      editPopup.image = service.images[0]
      editPopup.imageURL = service.imageURL[0]
      
      for (const cat of this.categories) {
        if (cat.text === service.category) {
          editPopup.category = cat
          break
        }
      }
      editPopup.categories = this.categories

      editPopup.servicesOffered = service.servicesOffered
      
      editPopup.availability = service.availability
      editPopup.session = service.session
      editPopup.schedule = service.schedule
      editPopup.subjects = service.subjects
console.log(editPopup.subjects);
      editPopup.servicePackage = service.packages.items[0]
      editPopup.initSubjects = true
      
      editPopup.description = service.description
      editPopup.setSchedule()
      editPopup.active = true
    },
    toggleDeleteService (service, index) {
      const deletePopup = this.$refs.deletePopup[index] 
      deletePopup.id = service.id
      deletePopup.index = index
      deletePopup.active = true
    },
    async viewService (service, index) {
      try {
        this.$vs.loading()
        const viewPopup = this.$refs.viewPopup
        const disabledDates = []
        const days = service.schedule.map(item => item.day)
        for (let i = 0; i < 7; i++) {
          if (!days.includes(i)) {
            disabledDates.push(i) 
          }
        }
        viewPopup.disabledDates.days = disabledDates
        viewPopup.schedule = service.schedule
  
        // create timeslot      
        viewPopup.session = service.session
        service.packages.items.map(item => { item.isSelected = false })
        viewPopup.packages = service.packages.items
        viewPopup.selectPackage = service.packages.items[0]
        viewPopup.serviceID = service.id
        viewPopup.timeslots = []
        
        const getUserRes = await API.graphql(
              graphqlOperation(getUser, {
                name: service.educatorRef.user,
              })
        );
        viewPopup.serviceUserData = getUserRes.data.getUser;

        const bookingRes = await API.graphql(graphqlOperation(sessionBookingByServiceId, {
          educatorServiceID: service.id
        }))

        viewPopup.bookings = bookingRes.data.sessionBookingByServiceID.items
        viewPopup.active = true
        this.$vs.loading.close()
      } catch (err) {
        console.log(err)
      }
    },
    serviceDetails (service) {
      this.$router.push(`/sessionlisting-details/${service.id}`)
      // this.$router
      //   .push({ 
      //     name: 'service-details',
      //     query: {
      //       name: 'ID',
      //       id: val.educatorID
      //     },
      //     params:{
      //        title:val.title
      //     }

      //   })
      //   .catch(() => {})
    },
    handleUpdateFinished ({ service, index }) {
      this.services.splice(index, 1, service)
    },
    handleDeleteFinished (index) {
      this.services.splice(index, 1)
    },
     async fetchCategory () {      
      try {        
        const res = await API.graphql(graphqlOperation(listCategorys))
        this.categories = res.data.listCategorys.items.map((item) => {
          return { text: item.name, val: item.id }          
        })         
      } catch (err) {
        console.log('fetchCategory', err)
      }
    }
  },
  created () {
    this.fetchServices()
    //axios.post(`${process.env.VUE_APP_BASE_URL}/StudyAreaList`).then(res => {
    //  this.categories = res.data.data.map(sa => { return {text: sa.study_area, val: sa.stu_area_id} })
   // })
   this.fetchCategory ()
  }
}
</script>

<style lang="scss">
.bg-grey-light{
  // background: #f0f0f0 !important;
  // color: rgba(0, 0, 0, 0.7) !important;
  background: rgb(96, 155, 252) !important;
  color: #fff !important;
}
.bg-custom-light{
  margin-right: 5px !important;
  margin-bottom: 8px !important;
  font-size: 13px !important;
  line-height: 1.1 !important;
  padding: 4px 8px !important;
  // font-weight: 600 !important;
  color: rgb(53, 64, 83) !important;
  background-color: rgb(228, 234, 240) !important;
  border-radius: 4px !important;
  word-break: break-word;
}
</style>