<template>
  <vs-popup :active.sync="isActive" title="Update Group ">
    <div class="px-3">
    <div class="vx-col w-full">
      <vs-input label="Title" v-model="title" class=" w-full" />

      <div class="mt-4">
        <label>Category</label>
        <v-select
          v-model="category"
          label="text"
          :options="categories"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
        />
      </div>

      <div class="mt-4">
        <label>Topics</label>
        <v-select
          v-model="topics"
          label="text"
          taggable
          multiple
          :options="topicsList"
          :closeOnSelect="false"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          @option:created="handleTopicOptionCreate"
        />
      </div>

      <vs-textarea
        class="mt-4"
        v-model="headline"
        label="Short Description"
        rows="1"
      />
    </div>

    <!-- <vs-textarea
      class="mt-4"
      v-model="description"
      label="Detailed Description"
      rows="5"
    /> -->
    <div class="mt-4">
      <label class="">Detailed Description</label>
      <quill-editor v-model="description"></quill-editor>
    </div>

    <div class="mt-4">
      <label>Group type</label>
      <v-select
        v-model="type"
        :options="types"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
      />
    </div>

    <div class="mt-4" v-if="type === 'Private'">
      <label class="">Actual price (Incl. Tax)</label>
      <vx-input-group
        class=""
        prependClasses="border border-solid border-grey border-r-0"
      >
        <template slot="prepend">
          <div class="prepend-text">
            <span>$</span>
          </div>
        </template>
        <vs-input v-model="price" type="number" />
      </vx-input-group>

      <label class="">Discount price ((Incl. Tax))</label>
      <vx-input-group
        class=""
        prependClasses="border border-solid border-grey border-r-0"
      >
        <template slot="prepend">
          <div class="prepend-text">
            <span>$</span>
          </div>
        </template>
        <vs-input v-model="discountPrice" type="number" />
      </vx-input-group>
    </div>

    <div class="mt-4">
      <div class="font-medium">Image</div>
      <div class="md:flex items-center">
        <div class="">
              <img :src="previewImage" width="200px" class="rounded-lg shadow-md mt-4" />
        </div>
       
       <div class="">
        <input ref="imageInput" type="file" @change="handleImageChange" />
        <vs-button class="md:ml-4 mt-3 md:mt-0" size="small" @click="toggleUpdateImage"
          >Upload Image</vs-button
        >
       </div>
      </div>
      

  <!--    <div class="mt-5">
        Coursefeed Video: {{ video.name }}
        <input ref="videoInput" type="file" @change="handleVideoChange" />
        <vs-button class="ml-4" size="small" @click="toggleUpdateVideo"
          >Update Video</vs-button
        >
      </div> -->
    </div>

<div class="text-right">
    <vs-button @click="handleUpdateCourseFeed" class="mt-5"
      >Update Group</vs-button
    >
</div>
    </div>
  </vs-popup>
</template>

<script>
import { API, graphqlOperation } from "@aws-amplify/api";
import { Storage } from "@aws-amplify/storage";
import { listCategorys, topicsByCategory } from "@/graphql/queries";
import { updateCourseFeed, createTopic } from "@/graphql/mutations";
import { createErrorObject, createSuccessObject } from "@/utils";
import vSelect from "vue-select";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "CourseFeedEditPopup",
  data() {
    return {
      isActive: false,
      id: "",
      index: "",
      title: "",
      category: "",
      categories: [],
      topics: [],
      topicsList: [],
      headline: "",
      description: "",
      type: "",
      types: ["Public", "Private"],
      price: -1,
      discountPrice: -1,
      image: "",
      previewImage: "",
      video: {
        name: "",
      },
    };
  },
  watch: {
    category(newVal) {
      this.topics = []
      this.topicsList = [];
      this.fetchTopic(newVal);
    },
  },
  components: {
    "v-select": vSelect,
    quillEditor,
  },
  methods: {
    toggleUpdateImage() {
      this.$refs.imageInput.click();
    },
    toggleUpdateVideo() {
      this.$refs.videoInput.click();
    },
    handleImageChange(e) {
      if (e.target.files[0]) {
        this.image = e.target.files[0];
        this.previewImage = URL.createObjectURL(this.image);
      }
    },
    handleVideoChange(e) {
      if (e.target.files[0]) {
        this.video = e.target.files[0];
      }
    },
    async handleTopicOptionCreate(newOption) {
      this.topics = [];
      let name = newOption.text ? newOption.text : newOption;
      try {
        const res = await API.graphql(
          graphqlOperation(createTopic, {
            input: {
              category: this.category.val,
              name,
            },
          })
        );

        this.topicsList.push({
          text: name,
          val: res.data.createTopic.id,
        });
      } catch (err) {
        console.log("handleTopicOptionCreate", err);
      }
    },
    async handleUpdateCourseFeed() {
      try {
        this.$vs.loading()
        let uploadImage = undefined;
        if (typeof this.image === "object") {
          uploadImage = await Storage.put(this.image.name, this.image);
        }

        let uploadVideo = undefined;
        if (typeof this.video === "object") {
          uploadVideo = await Storage.put(this.video.name, this.video);
        }

        const res = await API.graphql(
          graphqlOperation(updateCourseFeed, {
            input: {
              id: this.id,
              title: this.title,
              category: this.category.text,
              topics: this.topics.map((item) => item.text),
              headline: this.headline,
              description: this.description,
              type: this.type,
              price: this.price,
              discountPrice: this.discountPrice,
              image: uploadImage ? uploadImage.key : undefined,
              video: uploadVideo ? uploadVideo.key : undefined,
            },
          })
        );

        if (res.data.updateCourseFeed.image) {
          const url = await Storage.get(res.data.updateCourseFeed.image);
          res.data.updateCourseFeed.imageURL = url;
        }

        this.$emit("onUpdated", {
          courseFeed: res.data.updateCourseFeed,
          index: this.index,
        });

        this.$vs.notify(createSuccessObject("Success", "Course Feed updated"));
        this.isActive = false;
        this.$vs.loading.close()
      } catch (err) {
        this.$vs.notify(
          createErrorObject("Error", "Cannot update Course Feed")
        );
        console.log(err);
      }
    },
    async fetchCategory() {
      try {
        const res = await API.graphql(graphqlOperation(listCategorys));
        this.categories = res.data.listCategorys.items.map((item) => {
          return { text: item.name, val: item.id };
        });
      } catch (err) {
        console.log("fetchCategory", err);
      }
    },
    async fetchTopic(category) {
      try {
        const res = await API.graphql(
          graphqlOperation(topicsByCategory, {
            category: category.val,
          })
        );
        this.topicsList = res.data.topicsByCategory.items.map((item) => {
          return { text: item.name, val: item.id };
        });
      } catch (err) {
        console.log("fetchTopic", err);
      }
    },
  },
  created() {
    this.fetchCategory();
  },
};
</script>