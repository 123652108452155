<template>
  <div id="profile-page">
    <div class="vx-row">
      <div class="vx-col w-full">
  
      </div>
    </div>

    <vs-tabs alignment="center">

      <vs-tab label="Groups">
          <coursefeed-listing />
      </vs-tab>

     <vs-tab v-if="isPartner" label="Sessions">
        <session-listing />
      </vs-tab> 
    </vs-tabs>   

  </div>

</template>


<script>
import CoursefeedListing from './coursefeed-listing/CoursefeedListing'
import SessionListing from './session-listing/SessionListing'

export default {
  name: '',
  components: {
    SessionListing,
    CoursefeedListing
  },

  methods: {

  },
  computed: {
    isPartner () {
      return this.$store.state.auth.userData.isPartner
    },
  },
  mounted () {
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
  },
  created () {

  }
}
</script>

<style scoped>

</style>
